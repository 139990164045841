@font-face {
  font-family: 'Bukhari Script';
  src: 
       url('fonts/BukhariScript-Regular.ttf') format('truetype'),
       url('fonts/BukhariScript-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
  font-family: 'Bukhari Script', magneto, brush script mt, Lucida Console, cursive, fantasy, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-style: normal;
  font-weight: 100;
  color: #52e5ff;
  background-color: #4a0b35;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 36vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) and (prefers-color-scheme: dark) {
  .App-logo {
    animation: App-logo-spin infinite 1.2s linear;
  }
}

.App-header {
  background-color: #4a0b35;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #52e5ff;
}

.boys-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hits-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.blurb-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer {
  display: flex;
  flex: 1;
  padding: 2rem 0;
  font-size: 0.6rem;
  border-top: 3px solid #52e5ff;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.Card{
  margin: 2rem;
  padding: 1rem;;
  text-align: center;
  align-items: center;
  color: inherit;
  text-decoration: none;
  border: 5px solid #52e5ff;
  font-size: 0.7rem;
  border-radius: 20px;
  border-color: #52e5ff;
  background-color: #4a0b35;
  transition: color 0.7s ease, border-color 0.7s ease, background-color 0.7s ease;
  width: 300px;
}

.Card:hover,
.Card:focus,
.Card:active {
  color: #aa99ff;
  background-color: #4b7b99;
  border-color: #aa99ff;
}

.Big{
  font-size: 2rem;
}

.Stack {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0;
  margin: 2rem;
  margin-bottom: 8rem;
  width: 100%;
  }

.Grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 2rem;
  margin-bottom: 8rem;
  width: 100%;
  }

.GridTwo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 90%;
  max-width: 600px;
  padding: 0;
  margin: 2rem 0;
  border: 10px solid #52e5ff;
  border-radius: 20px;
  border-color: #52e5ff;
  transition: color 0.7s ease, border-color 0.7s ease;
}

.GridThree {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 80%;
  padding: 0.0rem;
  margin: 2rem;
  margin-bottom: 8rem;
  border: 10px solid #52e5ff;
  border-radius: 5px;
  border-color: #52e5ff;
  transition: color 0.7s ease, border-color 0.7s ease;
}

.GridFour {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 700px;
  max-width: 85%;
  padding: 2rem;
  margin: 2rem;
  margin-bottom: 8rem;
}

.GridFour a {
  padding: 1rem;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
